.rec-slider-container {
  margin: 0;
}

.rec-carousel-wrapper {
  position: relative;
}

.carousel-arrow:disabled {
  visibility: hidden;
}

.rec-item-wrapper {
  height: 100%;
}