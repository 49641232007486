.Header {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;

  &.isActive {
    .Header-navigation {
      @include media-breakpoint-down(lg) {
        background: $white;
        box-shadow: 0px 10px 10px rgba(64, 148, 106, 0.5);
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 84px;
        z-index: 1;
      }
    }

    .Header-mobileTogglerImage {
      &--sandwich {
        display: none;
      }

      &--cross {
        display: block;
        filter: invert(30%) sepia(15%) saturate(3099%) hue-rotate(105deg)
          brightness(94%) contrast(96%);
      }
    }
  }

  &-navigation {
    @include media-breakpoint-down(lg) {
      display: none;
      padding: 0 10px 30px;
    }
  }

  &-navigationList {
    display: flex;

    @include media-breakpoint-down(lg) {
      display: block;
      max-width: 200px;
    }
  }

  &-navigationListItem {
    margin: 0 20px 0 0;

    @include media-breakpoint-down(lg) {
      margin: 0 20px 10px 0;
      width: 100%;
    }

    &:last-child {
      margin: 0;
    }
  }

  &-navigationAction {
    @apply t-text-beta-700;
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 12px;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 36px;
    justify-content: center;
    letter-spacing: 0.1em;
    line-height: 16px;
    min-width: 36px;
    outline: 0;
    padding: 0 12px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.2s ease-out;

    @include media-breakpoint-down(lg) {
      justify-content: flex-start;
    }

    &:focus {
      &::after {
        background-color: $ship-gray;
        bottom: -2px;
        content: "";
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 16px;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    &:hover {
      &::after {
        background-color: $ship-gray;
        bottom: -2px;
        content: "";
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 16px;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    &.isActive {
      @include media-breakpoint-down(lg) {
        background-color: $paris-white;
        border-color: $paris-white;
        box-shadow: none;
      }

      &::after {
        background-color: $ship-gray;
        bottom: -2px;
        content: "";
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 16px;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  &-mobileToggler {
    align-items: center;
    background-color: $gin;
    border-radius: 12px;
    border: 0;
    display: none;
    height: 36px;
    justify-content: center;
    width: 36px;

    @include media-breakpoint-down(lg) {
      display: flex;
    }
  }

  &-mobileTogglerImage {
    pointer-events: none;

    &--sandwich {
      display: block;
    }

    &--cross {
      display: none;
    }
  }
}
