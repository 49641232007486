html {
  @apply t-bg-alpha-100;
  box-sizing: border-box;
  font-size: var(--font-size-md);
  height: 100%;
  line-height: var(--line-height-md);
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: 1px solid $spindle;
}

body {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-normal);
  height: 100%;
  line-height: var(--line-height-md);
  margin: 0;
  min-height: 100%;
  font-family: var(--font-family-primary);
  word-break: break-word;
}
