.__react_component_tooltip {
  border-radius: var(--border-radius-sm);
  box-shadow: 0px 4px 20px rgba($viridian, 0.1);
  max-width: 300px;
  font-size: var(--font-size-md);

  &.Documents-cardPreview {
    padding: 0;
    border-radius: var(--border-radius);

    &.show {
      color: inherit;
      box-shadow: none;
      border: none;
    }
  }

  &.show {
    background-color: $black-squeeze;
    border: 1px solid $link-water;
    box-shadow: 0 4px 10px $alto;
    color: $nepal;
    opacity: 1 !important;

    &.place-bottom {
      &::after {
        border-bottom-color: transparent;
      }
    }

    &.place-top {
      &::after {
        border-top-color: transparent;
      }
    }
  }
}
