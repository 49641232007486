.button {
  appearance: none;
  @apply t-bg-gamma-400 t-flex t-items-center t-justify-center
    t-text-center t-cursor-pointer t-rounded t-outline-none
    t-text-alpha-600 t-text-sm t-font-normal t-tracking-widest
    t-relative t-no-underline t-px-3.5;
  min-height: 36px;
  min-width: 36px;
  transition: 0.2s ease-out;
  &:disabled {
    @apply t-bg-beta-100 t-border-beta-100 t-shadow-none
    t-text-beta-500 t-pointer-events-none t-select-none;

    &:hover {
      @apply t-bg-beta-100 t-border-beta-100 t-shadow-none t-text-beta-500;
    }

    .button-icon {
      filter: invert(67%) sepia(0%) saturate(1449%) hue-rotate(145deg) brightness(87%) contrast(86%);
    }
  }
}

.button-disabled {
  @extend .button;
  @apply t-bg-beta-100 t-border-beta-100 t-shadow-none
    t-text-beta-500 t-pointer-events-none t-select-none;

  &:hover {
    @apply t-bg-beta-100 t-border-beta-100 t-shadow-none t-text-beta-500;
  }

  .button-icon {
    filter: invert(67%) sepia(0%) saturate(1449%) hue-rotate(145deg) brightness(87%) contrast(86%);
  }
}

.button-primary {
  @extend .button;
  @apply t-bg-alpha-600 t-border-alpha-600 t-text-gamma-400 t-shadow-none;

  &:hover {
    @apply t-bg-alpha-700 t-border-alpha-600 t-shadow-md t-shadow-alpha-600;
  }
}

.button-secondary {
  @extend .button;
  @apply t-bg-alpha-150 t-border-alpha-150 t-text-alpha-600 t-shadow-none;

  &:hover {
    @apply t-bg-alpha-200 t-border-alpha-200 t-shadow-none;
  }
}

.button-tertiary {
  @extend .button;
  @apply t-bg-gamma-400 t-border-none t-text-alpha-600 t-shadow;

  &:hover {
    @apply t-shadow-lg t-shadow-alpha-200;
  }
}

.button-ghost {
  @extend .button;
  @apply t-bg-gamma-400 t-text-alpha-600 t-border-none t-shadow-none;

  &:hover {
    @apply t-border t-border-solid t-border-alpha-150;
  }
}

.button-text {
  @extend .button;
  @apply t-p-0 t-bg-gamma-400 t-text-alpha-600 t-border-none t-shadow-none;
}

.button-danger {
  @extend .button;
  @apply t-bg-epsilon-100 t-border-epsilon-100 t-text-epsilon-600 t-shadow-none;

  &:hover {
    @apply t-bg-epsilon-600 t-border-epsilon-600 t-text-epsilon-100 t-shadow-none;
  }
}

.button-dangerous {
  @extend .button;
  @apply t-bg-epsilon-600 t-text-gamma-400 t-shadow-none;
}

.button-gray {
  @extend .button;
  @apply t-bg-beta-200 t-border-beta-200 t-text-beta-400 t-shadow-none;

  &:hover {
    @apply t-bg-beta-400 t-border-beta-400 t-text-gamma-400 t-shadow-none;
  }
}

.button-link {
  @extend .button;
  @apply t-bg-transparent t-border-none t-shadow-none t-text-delta-700
    t-text-xs t-tracking-normal t-leading-sm t-p-0 t-transition-none;
  height: initial;
  min-width: initial;

  &:hover {
    @apply t-underline;
  }
}

.button-icon {
  @apply t-relative -t-left-1.5 t-mr-1;
}

.button-icon-amazon {
  @extend .button-icon;
  filter: invert(43%) sepia(11%) saturate(1643%) hue-rotate(98deg) brightness(103%) contrast(93%);
}

.button-icon-white {
  @extend .button-icon;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(180deg) brightness(101%) contrast(101%);
}

.button-icon-chevroneDown {
  @extend .button-icon;
  @apply -t-left-1.5;
}

.button-icon-chevroneUp {
  @extend .button-icon;
  @apply -t-left-2 -t-top-1.5 t-transform t-rotate-180;
}

.button-icon-chevroneLeft {
  @extend .button-icon;
  @apply -t-left-2 t-transform t-rotate-90;
}
