.Accordion {
  &-item {
    border-top: 1px solid $mercury;
    padding: 16px 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid $mercury;
    }
  }

  &-itemTitle {
    padding-right: 5px;
  }

  &-info {
    align-items: center;
    display: flex;
    padding: 2px 0 0 0;
  }

  &-infoIcon {
    margin: 0 4px 0 0;
  }

  &-infoMark {
    margin: 0 6px 0 0;

    &.isAlmostReady {
      background-color: $cerise-red;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    &.isNotRelevant {
      background-color: $candle-light;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
  }
}
