.Spinner {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
  background: $gin2;

  &-action {
    @include loader05($size: 120px, $color: $viridian);
  }
}
