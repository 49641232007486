.Modal {
  padding: 40px 40px 30px;

  &--bottomBorderRadius {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &--topBorderRadius {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  &-close {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    position: absolute;
    right: 10px;
    top: 15px;
  }

  &-closeImage {
    filter: invert(66%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(87%) contrast(84%);
    pointer-events: none;
  }

  &-description {
    padding: 10px 0 24px;
  }

  &-info {
    background-color: $alabaster;
    border: 1px solid $seashell;
    border-radius: var(--border-radius-sm);
    padding: 14px 20px;
  }
}
