.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;

  &.small {
    .modal-dialog {
      max-width: 550px;
    }
  }

  &.medium {
    .modal-dialog {
      max-width: 800px;
    }
  }

  &.large {
    .modal-dialog {
      max-width: 75%;
      @include media-breakpoint-down(md) {
        max-width: 94%;
      }
    }
  }

  &.full-screen {
    .modal-dialog {
      position: fixed;
      overflow: scroll;
      margin: 0;
      max-width: 100%;
      max-height: 100%;
      padding: 0;
    }
  }
}

.modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-beta-900);

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

.modal-dialog {
  left: 50%;
  max-height: 80vh;
  max-width: 700px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
