/* our typography theme - following our style guide https://www.figma.com/file/Y0h1wCR8IB69UZgJ55jYqd/STYLE_Ninebarc?node-id=0%3A1  */

.typo-alpha {
  @apply t-font-secondary t-text-xl t-font-semibold t-leading-xl t-text-beta-700;
}

.typo-beta {
  @apply t-font-secondary t-text-lg t-font-semibold t-leading-lg t-text-beta-700;
}

.typo-gamma {
  @apply t-font-secondary t-text-lg t-font-normal t-leading-lg t-text-beta-700;
}

.typo-delta {
  @apply t-font-primary t-text-lg t-font-normal t-leading-lg t-text-beta-700;
}

.typo-epsilon {
  @apply t-font-primary t-text-md t-font-normal t-leading-md t-text-beta-700;
}

.typo-zeta {
  @apply t-font-primary t-text-md t-font-normal t-leading-md t-text-beta-500;
}

.typo-eta {
  @apply t-font-primary t-text-sm t-font-normal t-leading-sm t-text-beta-500 t-tracking-widest;
}

.typo-theta {
  @apply t-font-primary t-text-xs t-font-normal t-leading-sm t-text-beta-500;
}

.typo-iota {
  @apply t-font-primary t-text-xs t-font-normal t-leading-sm t-text-beta-400;
}

.typo-kappa {
  @apply t-font-primary t-text-xxs t-font-normal t-leading-xs t-text-beta-400 t-tracking-widest;
}

/* ######## */
/* old classes - we should remove them & implement the newer ones above in markup which uses the below, old ones */
.title {
  @apply t-font-secondary t-text-xl;
}

.subtitle {
  @apply t-font-secondary t-text-lg;
}

.text-main {
  @apply t-font-primary t-text-md;
}

.text-main-sm {
  @apply t-font-primary t-text-sm;
}
