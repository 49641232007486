@mixin loader05(
  $size: $loader-size,
  $color: $loader-color,
  $border-size: $loader-border-size,
  $duration: $loader-animation-duration,
  $align: null
) {
  width: $size;
  height: $size;
  border: $border-size solid $color;
  border-radius: 50%;
  position: relative;
  animation: loader-scale $duration ease-out infinite;
  @if ($align == center) {
    margin: 0 auto;
  }
  @if ($align == middle) {
    top: 50%;
    margin: -$size / 2 auto 0;
  }
  @include loader-scale;
}
