.Shadow {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--color-beta-900);
  opacity: 0.3;
  filter: alpha(opacity=30);
  z-index: 999;
}
