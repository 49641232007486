.Text {
  &-header {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    color: #093E62;

    @include media-breakpoint-down(xl) {
      font-size: 48px;
      line-height: 59px;
    }
  }

  &-header-sub {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #093E62;
  }

  &-normal {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #2793DD;
  }

  &-small {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  &-header-small {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
}