$alabaster: var(--color-beta-100);
$alto: var(--color-beta-300);
$amazon: var(--color-alpha-600);
$athens-gray: var(--color-beta-200);
$athens-gray: var(--color-delta-100);
$bali-hai: var(--color-beta-400);
$black-squeeze: var(--color-delta-100);
$boston-blue: var(--color-delta-500);
$candle-light: var(--color-zeta-600);
$carousel-pink: var(--color-epsilon-100);
$cerise-red: var(--color-epsilon-600);
$danube: var(--color-delta-500);
$fun-green: var(--color-alpha-700);
$gainsboro: var(--color-beta-100);
$gin-fizz: var(--color-eta-100);
$gin: var(--color-alpha-150);
$gin2: var(--color-alpha-100);
$gray-chateau: var(--color-beta-400);
$gray: var(--color-beta-500);
$hawkes-blue: var(--color-delta-200);
$jelly-bean: var(--color-delta-700);
$link-water: var(--color-delta-200);
$mercury: var(--color-beta-100);
$nepal: var(--color-delta-500);
$paris-white: var(--color-alpha-200);
$pumpkin: var(--color-beta-700);
$rolling-stone: var(--color-beta-500);
$sea-green: var(--color-alpha-700);
$seashell: var(--color-beta-100);
$ship-gray: var(--color-beta-700);
$sidecar: var(--color-eta-300);
$silver: var(--color-beta-400);
$spindle: var(--color-delta-300);
$tarawera: var(--color-delta-900);
$viridian: var(--color-alpha-600);
$white: var(--color-gamma-400);
$wild-sand: var(--color-beta-100);
