.SiteLanguage {
  display: flex;
  padding: 0 0 40px;

  &-button {
    background: transparent;
    border: 0;
    border-radius: var(--border-radius);
    padding: 10px;
    width: 38px;
    height: 38px;

    &.isActive {
      background-color: $gin;
    }
  }

  &-image {
    border-radius: 4px;
    display: block;
  }
}
