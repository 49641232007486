@for $width from 0 through 6 {
  .u-pb-#{$width * 10} {
    padding-bottom: #{$width * 10}#{'px'};
  }

  .u-pt-#{$width * 10} {
    padding-top: #{$width * 10}#{'px'};
  }
}

@for $width from 0 through 3 {
  .u-pl-#{$width * 10} {
    padding-left: #{$width * 10}#{'px'};
  }

  .u-pr-#{$width * 10} {
    padding-right: #{$width * 10}#{'px'};
  }
}

.u {
  &-color {
    &-amazon {
      color: $amazon;
    }

    &-boston-blue {
      color: $boston-blue;
    }

    &-cerise-red {
      color: $cerise-red;
    }

    &-gray {
      color: $gray;
    }

    &-gray-chateau {
      color: $gray-chateau;
    }

    &-jelly-bean {
      color: $jelly-bean;
    }

    &-gainsboro {
      color: $gainsboro;
    }

    &-nepal {
      color: $nepal;
    }

    &-mercury {
      color: $mercury;
    }

    &-rolling-stone {
      color: $rolling-stone;
    }

    &-ship-gray {
      color: $ship-gray;
    }

    &-tarawera {
      color: $tarawera;
    }

    &-white {
      color: $white;
    }
  }

  &-bg {
    &-alabaster {
      background-color: $alabaster;
    }

    &-black-squeeze {
      background-color: $black-squeeze;
    }

    &-gin {
      background-color: $gin;
    }

    &-gin2 {
      background-color: $gin2;
    }

    &-paris-white {
      background-color: $paris-white;
    }

    &-tarawera {
      background: $tarawera;
    }

    &-white {
      background: $white;
    }
  }

  &-p-10 {
    padding: 10px;
  }

  &-p-15 {
    padding: 15px;
  }

  &-pb-15 {
    padding-bottom: 15px;
  }

  &-pr-15 {
    padding-right: 15px;
  }

  &-pl-15 {
    padding-left: 15px;
  }

  &-pl-25 {
    padding-left: 15px;
  }

  &-pb-25 {
    padding-bottom: 25px;
  }

  &-pt-25 {
    padding-top: 25px;
  }

  &-mr-10 {
    margin-right: 10px;
  }

  &-mr-20 {
    margin-right: 20px;
  }

  &-mb-8 {
    margin-bottom: 8px;
  }

  &-mt-8 {
    margin-top: 8px;
  }

  &-weight {
    &-600 {
      font-weight: var(--font-weight-semibold);
    }
  }

  &-w {
    &-30 {
      width: 30%;
    }

    &-50 {
      width: 50%;
    }

    &-100 {
      width: 100%;
    }

    &-530 {
      width: 530px;
    }
  }

  &-h {
    &-100 {
      height: 100%;
    }

    &-430 {
      height: 430px;
    }

    &-475 {
      height: 475px;
    }
  }

  &-truncateLine {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-noUnderline {
    text-decoration: none;
  }

  &-noWrap {
    white-space: nowrap;
  }

  &-pointer {
    cursor: pointer;
  }

  &-relative {
    position: relative;
  }

  &-text {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-italic {
      font-style: italic;
    }
  }

  &-radius {
    &-50 {
      border-radius: 50%;
    }
  }

  &-opacity {
    &-7 {
      opacity: 0.7;
    }
  }

  &-flex {
    &-1 {
      flex: 1;
    }
  }

  &-zindex-max {
    z-index: 10000 !important;
  }
}
