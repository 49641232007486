@import url('https://fonts.cdnfonts.com/css/inter');

.lineThrough {
  text-decoration: line-through !important;
}

.dsg_custom_font {
  font-family: 'Inter', sans-serif !important;
}

.dsg_logo {
  height: 56px !important;
  width: auto;
}

.dsg_section_header {
  font-family: 'Inter', sans-serif !important;
  color: #A7163E !important;
}

.dsg_page_title {
  font-family: 'Inter', sans-serif !important;
  color: #A7163E !important;
}

.dsg_section_badge {
  font-family: 'Inter', sans-serif !important;
  color: #A7163E !important;
  background-color: #FFEFF0 !important;
}

.dsg_section_title {
  font-family: 'Inter', sans-serif !important;
  color: #FF7D23 !important;
}

.dsg_section_desc {
  font-family: 'Inter', sans-serif !important;
  color: #FF7D23 !important;
}

.dsg_text_title {
  font-family: 'Inter', sans-serif !important;
  color: #FF7D23 !important;
}

.dsg_text_desc {
  font-family: 'Inter', sans-serif !important;
  color: #FF7D23 !important;
}

.dsg_register_btn {
  font-family: 'Inter', sans-serif !important;
  background-color: #FF7D23;
  border-radius: 0px !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
  font-weight: 600;

  &:hover {
    background-color: #FF7D23 !important;
  }
}

.dsg_pointer {
  color: #A7163E !important;
}

.dsg_icon {
  color: #EF7C00 !important;
}

.dsg_icon_bg {
  background-color: #FFEADB !important;
}

.dsg_icon_border {
  border-color: #FF7D23 !important;
}

.dsg_hr {
  background-color: #FF7D23 !important;
}

.dsg_card_border {
  border-color: #FFEFF0 !important;
  box-shadow: 0px 4px 20px #FFEFF0 !important;
}

.dsg_footer {
  font-family: 'Inter', sans-serif !important;
  background: linear-gradient(90deg, #FF7D23 0%, #AD2142 63.02%) !important;
}

.dsg_next_button {
  outline: none;
  background-color: #FFEADB !important;
}

.dsg_next_button_bg {
  background-color: #A7163E !important;
}

.custom_client_section_1_footer {
  background: linear-gradient(#FFFFFF, #D6E8F6);
}