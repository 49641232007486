.Slider {
  .rc-slider-dot {
    display: none !important;
    pointer-events: none;
  }
  .rc-slider-mark-text {
    white-space: nowrap;
    color: $gray-chateau;
    bottom: 25px;
    position: absolute;
    .mark-text {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
    .text {
      position: absolute;
      right: 15px;
      bottom: 6px;
    }
    .arrow {
      margin-left: 10px;
      width: 10px;
      height: 15px;
      border-top: 1px solid $gray-chateau;
      border-right: 1px solid $gray-chateau;
    }
    &:hover {
      color: var(--color-beta-700);
    }
  }
}
