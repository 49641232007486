.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

.collapse:not(.show) {
  display: none;
}
